import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { baseUrl } from "api";

const provideListTag = ({ result, type }) => {
  return result?.results
    ? [...result.results.map(({ id }) => ({ type, id })), { type, id: "LIST" }]
    : [{ type, id: "LIST" }];
};

const projectCityApi = createApi({
  reducerPath: "projectCityApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().account;
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Accounts
    getAccountList: builder.query({
      query: () => "accounts/",
    }),
    getAccountDashboard: builder.query({
      query: (accountId) => `accounts/${accountId}/dashboard/`,
    }),
    createUpdatePaymentMethodSession: builder.mutation({
      query: () => ({
        url: `users/update_payment_method/`,
        method: "POST",
      }),
    }),
    updatePaymentMethodFromCheckout: builder.mutation({
      query: (payload) => {
        return {
          url: `users/update_payment_method_from_checkout/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Subscription"],
    }),

    // Assignments
    getAssignmentList: builder.query({
      query: ({ ...params }) => ({
        url: "assignments/",
        params,
      }),
      providesTags: (result) => provideListTag({ result, type: "Assignments" }),
    }),
    getAssignment: builder.query({
      query: ({ assignmentId }) => `assignments/${assignmentId}/`,
      providesTags: (result) => {
        return result?.id ? [{ type: "Assignments", id: result.id }] : [];
      },
    }),
    createAssignment: builder.mutation({
      query: (payload) => ({
        url: `assignments/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => {
        let array = [{ type: "Assignments", id: "LIST" }];

        // Invalidate the content upload detail so that the assignment shows up.
        if (result?.contentUpload)
          array.push({ type: "ContentUploads", id: result.contentUpload });

        return array;
      },
    }),
    deleteAssignment: builder.mutation({
      query: ({ assignmentId }) => ({
        url: `assignments/${assignmentId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => {
        return [
          { type: "Assignments", id: "LIST" },
          { type: "Assignments", id: arg.assignmentId },
        ];
      },
    }),
    updateAssignment: builder.mutation({
      query: ({ assignmentId, formData }) => ({
        url: `assignments/${assignmentId}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: (result, error, arg) => {
        return [
          { type: "Assignments", id: "LIST" },
          { type: "Assignments", id: arg.assignmentId },
        ];
      },
    }),
    createAssignmentMultipartUpload: builder.mutation({
      query: (payload) => ({
        url: `assignment-multipart-uploads/`,
        method: "POST",
        body: payload,
      }),
    }),
    uploadAssignmentMultipartPart: builder.mutation({
      query: ({ uuid, formData }) => ({
        url: `assignment-multipart-uploads/${uuid}/upload_part/`,
        method: "POST",
        body: formData,
      }),
    }),
    completeAssignmentMultipartUpload: builder.mutation({
      query: ({ uuid }) => ({
        url: `assignment-multipart-uploads/${uuid}/complete/`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Assignments", id: "LIST" }],
    }),

    // Assignment Entries
    getAssignmentEntryList: builder.query({
      query: ({ ...params }) => ({
        url: "assignment-entries/",
        params,
      }),
      providesTags: (result) =>
        provideListTag({ result, type: "AssignmentEntries" }),
    }),
    getNextAssignmentEntriesPage: builder.query({
      query: ({ next }) => {
        return { url: next };
      },
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch }) {
        /** Append the next page of data and update the next page url. */
        cacheDataLoaded.then((cacheData) => {
          const { results, next } = cacheData.data;
          dispatch(
            projectCityApi.util.updateQueryData(
              "getAssignmentEntryList",
              { assignment__id: arg.assignmentId },
              (draft) => {
                draft.results = [...draft.results, ...results];
                draft.next = next;
              }
            )
          );
        });
      },
    }),
    createAssignmentEntry: builder.mutation({
      query: ({ formData }) => ({
        url: `assignment-entries/`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [
        { type: "AssignmentEntries", id: "LIST" },
        { type: "Assignments", id: "LIST" },
      ],
    }),
    createAssignmentEntryFile: builder.mutation({
      query: (payload) => ({
        url: `assignment-entry-files/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => {
        return [
          { type: "AssignmentEntries", id: "LIST" },
          { type: "AssignmentEntries", id: arg.assignmentEntryId },
        ];
      },
    }),
    deleteAssignmentEntry: builder.mutation({
      query: ({ assignmentEntryId }) => ({
        url: `assignment-entries/${assignmentEntryId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => {
        return [
          { type: "AssignmentEntries", id: "LIST" },
          { type: "Assignments", id: "LIST" },
          { type: "AssignmentEntries", id: arg.assignmentEntryId },
        ];
      },
    }),
    updateAssignmentEntry: builder.mutation({
      query: ({ assignmentEntryId, formData }) => ({
        url: `assignment-entries/${assignmentEntryId}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: (result, error, arg) => {
        return [
          { type: "AssignmentEntries", id: "LIST" },
          { type: "AssignmentEntries", id: arg.assignmentEntryId },
        ];
      },
    }),
    toggleAssignmentEntryLike: builder.mutation({
      query: ({ assignmentEntryId }) => ({
        url: `assignment-entries/${assignmentEntryId}/like/`,
        method: "POST",
      }),
      async onQueryStarted({ assignmentEntryId, assignmentId }, { dispatch }) {
        // Optimistically update the like data.

        // Update the data in the assignment entry list data
        dispatch(
          projectCityApi.util.updateQueryData(
            "getAssignmentEntryList",
            { assignment__id: assignmentId },
            (draft) => {
              draft.results = draft.results.map((obj) => {
                if (parseInt(obj.id) !== parseInt(assignmentEntryId))
                  return obj;

                const newCount = obj.isLikedByCurrentUser
                  ? obj.likesCount - 1
                  : obj.likesCount + 1;

                return {
                  ...obj,
                  isLikedByCurrentUser: !obj.isLikedByCurrentUser,
                  likesCount: newCount,
                };
              });
              return draft;
            }
          )
        );
      },
    }),

    // Buckets
    getBucket: builder.query({
      query: ({ bucketId }) => `/buckets/${bucketId}/`,
    }),
    createBucket: builder.mutation({
      query: (payload) => ({
        url: `/buckets/`,
        method: "POST",
        body: payload,
      }),
    }),
    updateBucket: builder.mutation({
      query: ({ bucketId, ...payload }) => ({
        url: `/buckets/${bucketId}/`,
        method: "PATCH",
        body: payload,
      }),
    }),
    deleteBucket: builder.mutation({
      query: ({ bucketId }) => ({
        url: `/buckets/${bucketId}/`,
        method: "DELETE",
      }),
    }),

    // Bucket Uploads
    getBucketUpload: builder.query({
      query: ({ id }) => `/bucket-uploads/${id}/`,
    }),

    // Categories
    getCategoryList: builder.query({
      query: (params) => ({
        url: "categories/",
        params,
      }),
    }),

    // Comments
    getCommentList: builder.query({
      query: (params) => ({
        url: "comments/",
        params,
      }),
    }),
    getNextCommentPage: builder.query({
      query: ({ next, ...params }) => next,
      async onCacheEntryAdded(
        { next, ...params },
        { cacheDataLoaded, dispatch }
      ) {
        /** Append the next page of data and update the next page url. */
        cacheDataLoaded.then((cacheData) => {
          const { results, next } = cacheData.data;
          dispatch(
            projectCityApi.util.updateQueryData(
              "getCommentList",
              params,
              (draft) => {
                draft.results = [...draft.results, ...results];
                draft.next = next;
              }
            )
          );
        });
      },
    }),
    createComment: builder.mutation({
      query: (payload) => ({
        url: `comments/`,
        method: "POST",
        body: payload,
      }),
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch }) {
        /**
         * Append the new comment to the post to which it belongs.
         *
         * Comments are generally shown either as display on a post object, or can be displayed in
         * a comment container.
         */
        const cacheData = await cacheDataLoaded;
        const { contentType } = cacheData.data;

        // Choose which follow-up action to run, either adding the comment to a post content object
        // adding it to an object's comment list.

        arg.addToPreview
          ? dispatch(
              projectCityApi.util.updateQueryData(
                "getPostList",
                undefined,
                (draft) => {
                  /** Add the new comment to the proper post object based on the content type/object. */
                  draft.results = draft.results.map((post) => {
                    if (
                      post.contentType !== contentType ||
                      post.contentObject.id !== arg.objectId
                    ) {
                      return post;
                    } else {
                      return {
                        ...post,
                        contentObject: {
                          ...post.contentObject,
                          commentCount: post.contentObject.commentCount + 1,
                          comments: [
                            ...(post.contentObject.comments || []),
                            cacheData.data,
                          ],
                        },
                      };
                    }
                  });

                  return draft;
                }
              )
            )
          : dispatch(
              projectCityApi.util.updateQueryData(
                "getCommentList",
                {
                  object_id: arg.objectId,
                  content_type: arg.contentType,
                },
                (draft) => {
                  draft.results = [cacheData.data, ...draft.results];
                  return draft;
                }
              )
            );
      },
    }),
    updateComment: builder.mutation({
      query: ({ commentId, ...payload }) => ({
        url: `comments/${commentId}/`,
        method: "PATCH",
        body: payload,
      }),
      async onCacheEntryAdded({ commentId }, { dispatch, cacheDataLoaded }) {
        /**
         * Update the text of any of the comments that are stored in our state.
         *
         * There are various places that a comment can be stored in our state:
         *
         * 1) Post object's display comment
         * 2) Post object's comment list
         * 3) Comment list when viewing a full paginated comment list.
         */
        const { data } = await cacheDataLoaded;

        // Check if there is a post with display or extra comments that should be updated.
        dispatch(
          projectCityApi.util.updateQueryData(
            "getPostList",
            undefined,
            (draft) => {
              draft.results = draft.results.map((post) => {
                let { displayComment, comments } = post.contentObject;
                if (displayComment && displayComment.id === commentId) {
                  post.contentObject.displayComment = data;
                } else {
                  if (comments === undefined)
                    post.contentObject.comments = [data];
                  else
                    post.contentObject.comments = comments.map((comment) =>
                      comment.id === data.id ? data : comment
                    );
                }

                return post;
              });
            }
          )
        );

        // Update the comment if it's in a paginated full comment list.
        const {
          contentObject: { id: objectId },
          contentType,
        } = data;
        dispatch(
          projectCityApi.util.updateQueryData(
            "getCommentList",
            {
              object_id: objectId,
              content_type: contentType,
            },
            (draft) => {
              draft.results = [data, ...draft.results];
              return draft;
            }
          )
        );
      },
    }),
    deleteComment: builder.mutation({
      query: ({ commentId }) => ({
        url: `comments/${commentId}/`,
        method: "DELETE",
      }),
      async onQueryStarted({ commentId, objectId, contentType }, { dispatch }) {
        /**
         * Remove the comment from various places that it is stored in our state.
         *
         * 1) Post object's display comment
         * 2) Post object's comment list
         * 3) Comment list when viewing a full paginated comment list.
         */

        // Check if there is a post with display or extra comments that should be updated.
        dispatch(
          projectCityApi.util.updateQueryData(
            "getPostList",
            undefined,
            (draft) => {
              draft.results = draft.results.map((post) => {
                let { displayComment, comments = [] } = post.contentObject;
                if (displayComment?.id === commentId) {
                  delete post.contentObject.displayComment;
                  post.contentObject.commentCount -= 1;
                }

                post.contentObject.comments = comments.filter(
                  (comment) => comment.id !== commentId
                );

                return post;
              });
            }
          )
        );

        // Remove the comment from an object's paginated list of comments.
        dispatch(
          projectCityApi.util.updateQueryData(
            "getCommentList",
            {
              object_id: objectId,
              content_type: contentType,
            },
            (draft) => {
              draft.results = draft.results.filter(
                (comment) => comment.id !== commentId
              );
              return draft;
            }
          )
        );
      },
    }),
    createCommentReply: builder.mutation({
      query: ({ commentId, ...payload }) => ({
        url: `comments/${commentId}/add_reply/`,
        method: "POST",
        body: payload,
      }),
      async onCacheEntryAdded(
        { commentId, contentType, contentObject },
        { dispatch, cacheDataLoaded }
      ) {
        /**
         * Add the reply to the corresponding comment.
         *
         * There are various places that a comment can be stored in our state:
         *
         * 1) Post object's display comment
         * 2) Post object's comment list
         * 3) Comment list when viewing a full paginated comment list.
         */
        const { data: replyData } = await cacheDataLoaded;

        // Check if there is a post with display or extra comments that should be updated.
        dispatch(
          projectCityApi.util.updateQueryData(
            "getPostList",
            undefined,
            (draft) => {
              draft.results = draft.results.map((post) => {
                let { displayComment, comments = [] } = post.contentObject;
                if (displayComment && displayComment.id === commentId) {
                  post.contentObject.displayComment.replies.unshift(replyData);
                } else {
                  post.contentObject.comments = comments.map((comment) => {
                    if (comment.id === replyData.comment)
                      comment.replies = [replyData, ...comment.replies];

                    return comment;
                  });
                }

                return post;
              });
            }
          )
        );

        // Add the reply to the comment which is in a paginated modal.
        dispatch(
          projectCityApi.util.updateQueryData(
            "getCommentList",
            {
              object_id: contentObject.id,
              content_type: contentType,
            },
            (draft) => {
              draft.results = draft.results.map((comment) => {
                if (comment.id === replyData.comment)
                  comment.replies.unshift(replyData);
                return comment;
              });
              return draft;
            }
          )
        );
      },
    }),

    // Content Types
    getContentTypeList: builder.query({
      query: () => "content-types/",
    }),

    // Content Uploads
    getContentUploadList: builder.query({
      query: ({ ...params }) => ({
        url: "content-uploads/",
        params,
      }),
      providesTags: (result) => {
        return provideListTag({ result, type: "ContentUploads" });
      },
    }),
    getNextContentUploadPage: builder.query({
      query: ({ next }) => {
        const url = new URL(next);
        return url.pathname + url.search;
      },
      async onCacheEntryAdded(args, { cacheDataLoaded, dispatch }) {
        /** Append the next page of data and update the next page url. */
        cacheDataLoaded.then((cacheData) => {
          const { results, next } = cacheData.data;

          // Remove the next arg and pass the rest through to the cache key.
          let copyArgs = { ...args };
          delete copyArgs.next;

          dispatch(
            projectCityApi.util.updateQueryData(
              "getContentUploadList",
              copyArgs,
              (draft) => {
                draft.results = [...draft.results, ...results];
                draft.next = next;
              }
            )
          );
        });
      },
    }),
    getContentUploadDetail: builder.query({
      query: ({ contentUploadId }) => ({
        url: `content-uploads/${contentUploadId}/`,
      }),
      providesTags: (result) => {
        return result ? [{ type: "ContentUploads", id: result.id }] : [];
      },
    }),
    createContentUpload: builder.mutation({
      query: (payload) => ({
        url: `content-uploads/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "ContentUploads", id: "LIST" }],
    }),
    toggleContentUploadLike: builder.mutation({
      query: ({ contentUploadId }) => ({
        url: `content-uploads/${contentUploadId}/like/`,
        method: "POST",
      }),
      async onQueryStarted({ contentUploadId }, { dispatch }) {
        // Optimistically update the like data.

        // Set the data in the contest upload detail
        dispatch(
          projectCityApi.util.updateQueryData(
            "getContentUploadDetail",
            { contentUploadId },
            (draft) => {
              const newCount = draft.isLikedByCurrentUser
                ? draft.likesCount - 1
                : draft.likesCount + 1;
              return {
                ...draft,
                isLikedByCurrentUser: !draft.isLikedByCurrentUser,
                likesCount: newCount,
              };
            }
          )
        );

        // Set the data in the contest upload list
        dispatch(
          projectCityApi.util.updateQueryData(
            "getContentUploadList",
            {},
            (draft) => {
              draft.results = draft.results.map((contentUpload) => {
                if (parseInt(contentUpload.id) !== parseInt(contentUploadId))
                  return contentUpload;

                const newCount = contentUpload.isLikedByCurrentUser
                  ? contentUpload.likesCount - 1
                  : contentUpload.likesCount + 1;

                return {
                  ...contentUpload,
                  isLikedByCurrentUser: !contentUpload.isLikedByCurrentUser,
                  likesCount: newCount,
                };
              });
            }
          )
        );
      },
    }),
    updateContentUpload: builder.mutation({
      query: ({ contentUploadId, formData }) => {
        return {
          url: `content-uploads/${contentUploadId}/`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "ContentUploads", id: "LIST" }],
    }),
    deleteContentUpload: builder.mutation({
      query: ({ contentUploadId }) => {
        return {
          url: `content-uploads/${contentUploadId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "ContentUploads", id: "LIST" }],
    }),
    reorderContentUpload: builder.mutation({
      query: ({ contentUploadId, ...payload }) => ({
        url: `content-uploads/${contentUploadId}/reorder/`,
        method: "POST",
        body: payload,
      }),
    }),

    // Content Upload Multipart
    createContentMultipartUpload: builder.mutation({
      query: (payload) => ({
        url: `content-multipart-uploads/`,
        method: "POST",
        body: payload,
      }),
    }),
    uploadContentMultipartPart: builder.mutation({
      query: ({ uuid, formData }) => ({
        url: `content-multipart-uploads/${uuid}/upload_part/`,
        method: "POST",
        body: formData,
      }),
    }),
    completeContentMultipartUpload: builder.mutation({
      query: ({ uuid }) => ({
        url: `content-multipart-uploads/${uuid}/complete/`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "ContentUploads", id: "LIST" }],
    }),
    donateContentUpload: builder.mutation({
      query: ({ contentUploadId, ...payload }) => ({
        url: `content-uploads/${contentUploadId}/donate/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, { contentUploadId }) => {
        return [
          { type: "ContentUploads", id: "LIST" },
          { type: "ContentUploads", id: contentUploadId },
          { type: "Donations", id: "LIST" },
        ];
      },
    }),
    createContentUploadTokenPurchase: builder.mutation({
      query: ({ contentUploadId, ...payload }) => ({
        url: `content-uploads/${contentUploadId}/purchase_tokens/`,
        method: "POST",
        body: payload,
      }),
    }),

    // Contests
    getContestList: builder.query({
      query: ({ ...params }) => ({
        url: "contests/",
        params,
      }),
    }),
    getContest: builder.query({
      query: ({ contestId }) => `contests/${contestId}`,
    }),

    // Contest Entries
    getContestEntriesList: builder.query({
      query: ({ ...params }) => ({
        url: "contest-entries/",
        params,
      }),
      providesTags: (result) =>
        provideListTag({ result, type: "ContestEntries" }),
    }),
    createContestEntry: builder.mutation({
      query: (payload) => ({
        url: `contest-entries/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "ContestEntries", id: "LIST" }],
    }),
    updateContestEntry: builder.mutation({
      query: ({ contestEntryId, formData }) => ({
        url: `contest-entries/${contestEntryId}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: [{ type: "ContestEntries", id: "LIST" }],
    }),
    deleteContestEntry: builder.mutation({
      query: ({ contestEntryId }) => ({
        url: `contest-entries/${contestEntryId}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "ContestEntries", id: "LIST" }],
    }),
    toggleContestEntryLike: builder.mutation({
      query: ({ contestEntryId }) => ({
        url: `contest-entries/${contestEntryId}/like/`,
        method: "POST",
      }),
      async onQueryStarted({ contestEntryId, contestId }, { dispatch }) {
        // Optimistically update the like data.

        // Update the data in the contest entry list data
        dispatch(
          projectCityApi.util.updateQueryData(
            "getContestEntriesList",
            { contest: contestId.toString() },
            (draft) => {
              draft.results = draft.results.map((obj) => {
                if (parseInt(obj.id) !== parseInt(contestEntryId)) return obj;

                const newCount = obj.isLikedByCurrentUser
                  ? obj.likesCount - 1
                  : obj.likesCount + 1;

                return {
                  ...obj,
                  isLikedByCurrentUser: !obj.isLikedByCurrentUser,
                  likesCount: newCount,
                };
              });
            }
          )
        );
      },
    }),
    getNextContestEntryPage: builder.query({
      query: ({ next, contestId }) => {
        return { url: next };
      },
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch }) {
        /** Append the next page of data and update the next page url. */
        cacheDataLoaded.then((cacheData) => {
          const { results, next } = cacheData.data;
          dispatch(
            projectCityApi.util.updateQueryData(
              "getContestEntriesList",
              { contest: arg.contestId },
              (draft) => {
                draft.results = [...draft.results, ...results];
                draft.next = next;
              }
            )
          );
        });
      },
    }),

    // Donations
    getDonationList: builder.query({
      query: (params) => ({
        url: "donations/",
        params,
      }),
      providesTags: (result) => provideListTag({ result, type: "Donations" }),
    }),
    getDonationAggregate: builder.query({
      query: () => `donations/aggregate/`,
    }),

    // Donation Payouts
    getDonationPayoutList: builder.query({
      query: () => "donation-payouts/",
    }),
    getDonationPayoutDetail: builder.query({
      query: ({ donationTransferId }) =>
        `donation-payouts/${donationTransferId}/`,
    }),

    // Gift Purchases
    createGiftPurchase: builder.mutation({
      query: (payload) => ({
        url: `gift-purchases/`,
        method: "POST",
        body: payload,
      }),
    }),

    // Monthly Sprint Winners
    getMonthlyWinnerList: builder.query({
      query: () => ({
        url: "monthly-winners/",
      }),
    }),

    // Notifications
    getNotificationList: builder.query({
      query: () => `notifications/`,
    }),
    updateNotification: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `notifications/${id}/`,
        method: "PATCH",
        body: payload,
      }),
      async onQueryStarted({ id }, { dispatch }) {
        /** Optimistically set all the notification as seen. */
        dispatch(
          projectCityApi.util.updateQueryData(
            "getNotificationList",
            undefined,
            (draft) => {
              draft.results = draft.results.map((notification) =>
                notification.id === id
                  ? { ...notification, seen: true }
                  : notification
              );
            }
          )
        );
      },
    }),
    setNotificationsSeen: builder.mutation({
      query: () => ({
        url: `notifications/mark_all_seen/`,
        method: "POST",
      }),
      async onQueryStarted(arg, { dispatch }) {
        /** Optimistically set all the notifications as seen. */
        dispatch(
          projectCityApi.util.updateQueryData(
            "getNotificationList",
            arg,
            (draft) => {
              draft.results = draft.results.map((notification) => ({
                ...notification,
                seen: true,
              }));
            }
          )
        );
      },
    }),

    // Payouts
    getPayoutList: builder.query({
      query: () => "payouts/",
    }),

    // Posts
    getPostList: builder.query({
      query: () => ({
        url: "posts/",
      }),
      providesTags: (result) => provideListTag({ result, type: "Posts" }),
    }),
    getNextPostPage: builder.query({
      query: ({ next }) => {
        const url = new URL(next);
        return url.pathname + url.search;
      },
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch }) {
        /** Append the next page of data and update the next page url. */
        cacheDataLoaded.then((cacheData) => {
          const { results, next } = cacheData.data;
          dispatch(
            projectCityApi.util.updateQueryData(
              "getPostList",
              undefined,
              (draft) => {
                draft.results = [...draft.results, ...results];
                draft.next = next;
              }
            )
          );
        });
      },
    }),
    createPost: builder.mutation({
      query: (payload) => ({
        url: `posts/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "Posts", id: "LIST" }],
    }),
    updatePost: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `posts/${id}/`,
        method: "PATCH",
        body: payload,
      }),
      async onCacheEntryAdded({ id }, { cacheDataLoaded, dispatch }) {
        /** Update the post data in our cache. */
        const { data } = await cacheDataLoaded;
        dispatch(
          projectCityApi.util.updateQueryData(
            "getPostList",
            undefined,
            (draft) => {
              draft.results = draft.results.map((post) => {
                return post.id !== id ? post : data;
              });
            }
          )
        );
      },
    }),
    deletePost: builder.mutation({
      query: ({ id }) => ({
        url: `posts/${id}/`,
        method: "DELETE",
      }),
      async onQueryStarted({ id }, { dispatch }) {
        /** Optimistically remove the post from our cache. */
        dispatch(
          projectCityApi.util.updateQueryData(
            "getPostList",
            undefined,
            (draft) => {
              draft.results = draft.results.filter((post) => {
                return post.id !== id;
              });
            }
          )
        );
      },
    }),
    togglePostLike: builder.mutation({
      query: ({ id }) => ({
        url: `posts/${id}/like/`,
        method: "POST",
      }),
      async onQueryStarted({ id }, { dispatch }) {
        /** Update post after toggling the like. */
        dispatch(
          projectCityApi.util.updateQueryData(
            "getPostList",
            undefined,
            (draft) => {
              draft.results = draft.results.map((post) => {
                return post.id !== id
                  ? post
                  : {
                      ...post,
                      isLikedByCurrentUser: !post.isLikedByCurrentUser,
                      likesCount: post.isLikedByCurrentUser
                        ? post.likesCount - 1
                        : post.likesCount + 1,
                    };
              });
            }
          )
        );
      },
    }),

    // Projects
    getProject: builder.query({
      query: (params) => `projects/${params.projectId}`,
    }),
    getProjectList: builder.query({
      query: (params) => ({
        url: "projects/",
        params: {
          ...params,
          mobile: params.mobile || undefined,
        },
      }),
    }),
    getNextProjectPage: builder.query({
      query: ({ next }) => {
        const url = new URL(next);
        return url.pathname + url.search;
      },
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch }) {
        /** Append the next page of data and update the next page url. */
        const cacheData = await cacheDataLoaded;
        const { results, next } = cacheData.data;
        dispatch(
          projectCityApi.util.updateQueryData(
            "getProjectList",
            { type: arg.type },
            (draft) => {
              draft.results = [...draft.results, ...results];
              draft.next = next;
            }
          )
        );
      },
    }),
    setProjectAccount: builder.mutation({
      query: ({ projectId, payload }) => ({
        url: `projects/${projectId}/set_account/`,
        method: "POST",
        body: payload,
      }),
    }),

    // Revenue Splits
    getRevenueSplitList: builder.query({
      query: (params) => ({
        url: "revenue-splits/",
        params,
      }),
      providesTags: (result) =>
        provideListTag({ result, type: "RevenueSplits" }),
    }),
    createRevenueSplit: builder.mutation({
      query: (payload) => ({
        url: "revenue-splits/",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: () => {
        return [{ type: "RevenueSplits", id: "LIST" }];
      },
    }),
    updateRevenueSplit: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `revenue-splits/${id}/`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: () => {
        return [{ type: "RevenueSplits", id: "LIST" }];
      },
    }),
    deleteRevenueSplit: builder.mutation({
      query: ({ id }) => ({
        url: `revenue-splits/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: () => {
        return [{ type: "RevenueSplits", id: "LIST" }];
      },
    }),

    // Subscriptions
    applyPromotionCode: builder.mutation({
      query: ({ subscriptionId, ...payload }) => ({
        url: `/subscriptions/apply_discount/`,
        method: "POST",
        body: payload,
      }),
    }),
    getSubscriptionData: builder.query({
      query: ({ subscriptionId }) => `/subscriptions/${subscriptionId}/data/`,
      providesTags: ["Subscription"],
    }),
    getPaymentUrl: builder.query({
      query: ({ subscriptionId }) =>
        `/subscriptions/${subscriptionId}/payment_url/`,
    }),
    getSubscriptionInvoices: builder.query({
      query: ({ subscriptionId }) =>
        `/subscriptions/${subscriptionId}/invoices/`,
    }),
    removeCancelSubscription: builder.mutation({
      query: ({ subscriptionId, ...payload }) => ({
        url: `/subscriptions/${subscriptionId}/remove_cancel/`,
        method: "POST",
      }),
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch }) {
        const cacheData = await cacheDataLoaded;
        const { data } = cacheData;
        dispatch(
          projectCityApi.util.updateQueryData(
            "getSubscriptionData",
            arg,
            () => data
          )
        );
      },
    }),
    cancelSubscription: builder.mutation({
      query: ({ subscriptionId, ...payload }) => ({
        url: `/subscriptions/${subscriptionId}/cancel/`,
        method: "POST",
        body: payload,
      }),
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch }) {
        const cacheData = await cacheDataLoaded;
        const { data } = cacheData;
        dispatch(
          projectCityApi.util.updateQueryData(
            "getSubscriptionData",
            { subscriptionId: arg.subscriptionId },
            (draft) => {
              return data;
            }
          )
        );
      },
    }),
    upgradeToAnnual: builder.mutation({
      query: () => ({
        url: `/subscriptions/upgrade/`,
        method: "POST",
      }),
    }),
    upgradePreview: builder.query({
      query: () => "/subscriptions/upgrade_preview/",
    }),

    // Tours
    registerTour: builder.mutation({
      query: (payload) => ({
        url: `tours/`,
        method: "POST",
        body: payload,
      }),
    }),

    // Upload Schedules
    getUploadSchedules: builder.query({
      query: () => "upload-schedules/",
      providesTags: (result) =>
        provideListTag({ result, type: "UploadSchedules" }),
    }),
    createUploadSchedule: builder.mutation({
      query: (payload) => ({
        url: `upload-schedules/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "UploadSchedules", id: "LIST" }],
    }),
    updateUploadSchedule: builder.mutation({
      query: ({ uploadScheduleId, ...payload }) => ({
        url: `upload-schedules/${uploadScheduleId}/`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: [{ type: "UploadSchedules", id: "LIST" }],
    }),
    deleteUploadSchedule: builder.mutation({
      query: ({ uploadScheduleId }) => ({
        url: `upload-schedules/${uploadScheduleId}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "UploadSchedules", id: "LIST" }],
    }),

    // Upload Schedule Sections
    createUploadScheduleSection: builder.mutation({
      query: (payload) => ({
        url: `upload-schedule-sections/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "UploadSchedules", id: "LIST" }],
    }),
    updateUploadScheduleSection: builder.mutation({
      query: ({ uploadScheduleSectionId, ...payload }) => ({
        url: `upload-schedule-sections/${uploadScheduleSectionId}/`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: [{ type: "UploadSchedules", id: "LIST" }],
    }),
    deleteUploadScheduleSection: builder.mutation({
      query: ({ uploadScheduleSectionId }) => ({
        url: `upload-schedule-sections/${uploadScheduleSectionId}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "UploadSchedules", id: "LIST" }],
    }),

    // Upload Schedule Dates
    getUploadScheduleDates: builder.query({
      query: (params) => ({
        url: `upload-schedule-dates/`,
        params,
      }),
      providesTags: (result) =>
        provideListTag({ result, type: "UploadScheduleDates" }),
    }),
    createUploadScheduleDate: builder.mutation({
      query: (payload) => ({
        url: `upload-schedule-dates/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [
        { type: "UploadSchedules", id: "LIST" },
        { type: "UploadScheduleDates", id: "LIST" },
      ],
    }),
    updateUploadScheduleDate: builder.mutation({
      query: ({ uploadScheduleDateId, ...payload }) => ({
        url: `upload-schedule-dates/${uploadScheduleDateId}/`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: [
        { type: "UploadSchedules", id: "LIST" },
        { type: "UploadScheduleDates", id: "LIST" },
      ],
    }),
    deleteUploadScheduleDate: builder.mutation({
      query: ({ uploadScheduleDateId }) => ({
        url: `upload-schedule-dates/${uploadScheduleDateId}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "UploadSchedules", id: "LIST" },
        { type: "UploadScheduleDates", id: "LIST" },
      ],
    }),

    // Users
    getProfile: builder.query({
      query: ({ username }) => ({
        url: `users/${username}/`,
      }),
    }),
    createSubscriptionCheckout: builder.mutation({
      query: (payload) => {
        return {
          url: `users/create_subscription_checkout/`,
          method: "POST",
          body: payload,
        };
      },
    }),
    donationAgreement: builder.mutation({
      query: () => ({
        url: "users/donation_agree/",
        method: "POST",
      }),
    }),
    getSprintCreators: builder.query({
      query: ({ username }) => ({
        url: `users/sprint_creators/?username=${username}`,
      }),
      providesTags: ["SprintCreators"],
    }),
    userSearch: builder.query({
      query: ({ username }) => ({
        url: `users/search-user-list/${username}/`,
      }),
    }),

    // Video Watch Sessions
    createWatchSession: builder.mutation({
      query: (payload) => ({
        url: `video-watch-sessions/`,
        method: "POST",
        body: payload,
      }),
    }),
    updateWatchSession: builder.mutation({
      query: ({ videoWatchSessionId, ...payload }) => ({
        url: `video-watch-sessions/${videoWatchSessionId}/`,
        method: "PATCH",
        body: payload,
      }),
    }),

    // Add this with the other project-related endpoints
    updateProject: builder.mutation({
      query: ({ projectId, payload }) => ({
        url: `projects/${projectId}/`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: (result, error, { projectId }) => [
        { type: "Projects", id: projectId },
        { type: "Projects", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetAccountListQuery,
  useGetAccountDashboardQuery,
  useCreateUpdatePaymentMethodSessionMutation,
  useUpdatePaymentMethodFromCheckoutMutation,
  useGetAssignmentListQuery,
  useGetAssignmentQuery,
  useCreateAssignmentMutation,
  useDeleteAssignmentMutation,
  useUpdateAssignmentMutation,
  useCreateAssignmentMultipartUploadMutation,
  useUploadAssignmentMultipartPartMutation,
  useCompleteAssignmentMultipartUploadMutation,
  useCreateAssignmentEntryMutation,
  useCreateAssignmentEntryFileMutation,
  useDeleteAssignmentEntryMutation,
  useUpdateAssignmentEntryMutation,
  useGetAssignmentEntryListQuery,
  useGetNextAssignmentEntriesPageQuery,
  useToggleAssignmentEntryLikeMutation,
  useGetCategoryListQuery,
  useGetCommentListQuery,
  useGetNextCommentPageQuery,
  useCreateCommentMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
  useCreateCommentReplyMutation,
  useGetContentTypeListQuery,
  useGetContentUploadDetailQuery,
  useGetContentUploadListQuery,
  useGetNextContentUploadPageQuery,
  useCreateContentMultipartUploadMutation,
  useUploadContentMultipartPartMutation,
  useCompleteContentMultipartUploadMutation,
  useDonateContentUploadMutation,
  useCreateContentUploadTokenPurchaseMutation,
  useCreateContentUploadMutation,
  useUpdateContentUploadMutation,
  useDeleteContentUploadMutation,
  useReorderContentUploadMutation,
  useToggleContentUploadLikeMutation,
  useGetContestListQuery,
  useGetContestEntriesListQuery,
  useCreateContestEntryMutation,
  useUpdateContestEntryMutation,
  useDeleteContestEntryMutation,
  useToggleContestEntryLikeMutation,
  useGetNextContestEntryPageQuery,
  useGetContestQuery,
  useGetBucketQuery,
  useGetBucketUploadQuery,
  useGetDonationListQuery,
  useGetDonationAggregateQuery,
  useGetDonationPayoutListQuery,
  useGetDonationPayoutDetailQuery,
  useGetNotificationListQuery,
  useCreateGiftPurchaseMutation,
  useGetMonthlyWinnerListQuery,
  useSetNotificationsSeenMutation,
  useUpdateNotificationMutation,
  useGetPayoutListQuery,
  useGetPostListQuery,
  useGetNextPostPageQuery,
  useCreatePostMutation,
  useTogglePostLikeMutation,
  useUpdatePostMutation,
  useDeletePostMutation,
  useGetProjectQuery,
  useSetProjectAccountMutation,
  useGetProjectListQuery,
  useGetRevenueSplitListQuery,
  useCreateRevenueSplitMutation,
  useUpdateRevenueSplitMutation,
  useDeleteRevenueSplitMutation,
  useApplyPromotionCodeMutation,
  useGetSubscriptionDataQuery,
  useGetPaymentUrlQuery,
  useGetSubscriptionInvoicesQuery,
  useCancelSubscriptionMutation,
  useUpgradeToAnnualMutation,
  useUpgradePreviewQuery,
  useRemoveCancelSubscriptionMutation,
  useRegisterTourMutation,
  useGetUploadSchedulesQuery,
  useCreateUploadScheduleMutation,
  useUpdateUploadScheduleMutation,
  useDeleteUploadScheduleMutation,
  useCreateUploadScheduleSectionMutation,
  useUpdateUploadScheduleSectionMutation,
  useDeleteUploadScheduleSectionMutation,
  useGetUploadScheduleDatesQuery,
  useCreateUploadScheduleDateMutation,
  useUpdateUploadScheduleDateMutation,
  useDeleteUploadScheduleDateMutation,
  useGetProfileQuery,
  useDonationAgreementMutation,
  useGetSprintCreatorsQuery,
  useUserSearchQuery,
  useCreateSubscriptionCheckoutMutation,
  useCreateWatchSessionMutation,
  useUpdateWatchSessionMutation,
  usePrefetch,
} = projectCityApi;

export default projectCityApi;
